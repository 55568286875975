import { useEffect, useState } from "react";
import { useSignAndExecuteTransaction, useSuiClient } from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { QueryWrapper } from "./QueryWrapper";
import { buildOpenLootBoxTx } from "@/utils/mintNft";
import {
  apiProduction,
  apiStage,
  DataStorage,
  EMPTY_TP,
  isProdUrl,
  PACKAGE_ADDRESS,
} from "@/constants";
import ky from "ky";
import { OpenMoneyBagSucess } from "@/components/OpenMoneyBagSucess/OpenMoneyBagSucess";
import { KioskClient, Network } from "@mysten/kiosk";
import { Transaction } from "@mysten/sui/transactions";
const base = isProdUrl ? apiProduction : apiStage;

export const StashedOpeningMoneyBag = () => {
  const query = new URLSearchParams(window.location.search);
  const client = useSuiClient();

  const kioskClient = new KioskClient({
    client,
    network: Network.MAINNET,
  });

  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [transacrion, setTransacrion] = useState<Transaction | undefined>();
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [reward, setReward] = useState(0);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(false);
  const [isMoneyBagSucess, setIsMoneyBagSuccess] = useState(false);

  const goToStahsed = (openLootboxTx: Transaction) => {
    setStashedWalletIsOpen(false);

    return signAndExecuteTransaction(
      {
        transaction: openLootboxTx,
        chain: "sui:mainnet",
      },
      {
        onSuccess: (result) => {
          setIsLoader(true);
          ky.post(base + `/api/nft/lootbox/open_tx`, {
            headers: {
              Authorization: `Bearer ${query.get("token") ?? ""}`,
            },
            json: {
              tx_digest: result.digest,
            },
          })
            .then((result) => {
              result.json().then((result) => {
                const handledResult = result as {
                  ok: boolean;
                  reward: number;
                  err?: string;
                };
                if (handledResult.ok) {
                  setReward(handledResult.reward);
                  setIsMoneyBagSuccess(true);
                } else {
                  setIsError(true);
                  setErrorText(handledResult.err ?? "");
                }
                setIsLoader(false);
              });
            })
            .catch((err) => {
              if (err.response) {
                err.response
                  .json()
                  .then(
                    (errResult: {
                      ok: boolean;
                      reward: number;
                      err?: string;
                    }) => {
                      setErrorText(errResult.err ?? "Unknown server error");
                    }
                  )
                  .catch(() => {});
              }
              setIsLoader(false);
              setIsError(true);
            });
        },
        onError: (err) => {
          console.log("Error", err);
          setIsLoader(false);
        },
      }
    );
  };

  const transfer = async ({
    moneyBagId,
    address,
  }: {
    moneyBagId: string;
    address: string;
  }) => {
    setIsLoader(true);

    if (!transacrionInWork) {
      setTransacrionInWork(true);
      return buildOpenLootBoxTx({
        transferLootboxId: moneyBagId,
        dataStorage: DataStorage,
        packageAddress: PACKAGE_ADDRESS,
        emptyTp: EMPTY_TP,
        userAddress: address,
        kioskClient,
      });
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const moneyBagId = query.get("moneyBagId");
    const address = query.get("address");

    const trasactionData = {
      moneyBagId: moneyBagId ?? "",
      address: address ?? "",
    };

    if (address && moneyBagId) {
      transfer(trasactionData).then((result) => {
        setTransacrion(result);
        setIsLoader(false);
        setStashedWalletIsOpen(true);
      });
    }
  }, []);

  return (
    <QueryWrapper requiredParams={["moneyBagId", "address"]}>
      {({ handleClose }) => {
        return (
          <>
            {isLoader && <Loader />}
            <Dialog
              modalWallpaper={walletBg}
              isOpen={stashedWalletIsOpen}
              onClose={handleClose}
              onAction={() => transacrion && goToStahsed(transacrion)}
              title={"Connecting is finished"}
              text={"Click the button below to open your money bag"}
              modalTitleColor={"#C0C3FF"}
              modalIcon={iosWalletImg}
              buttonText={"Open Money Bag"}
            />

            {reward && (
              <OpenMoneyBagSucess
                isOpen={isMoneyBagSucess}
                onClose={() => {
                  handleClose();
                  setIsMoneyBagSuccess(false);
                }}
                onAction={() => {
                  handleClose();
                  setIsMoneyBagSuccess(false);
                }}
                reward={reward}
              />
            )}
            <Dialog
              modalWallpaper={walletBg}
              isOpen={isError}
              onClose={() => {
                setIsError(false);
                handleClose();
              }}
              onAction={() => {
                setIsError(false);
                handleClose();
              }}
              title={"Something went wrong"}
              text={
                "Looks like something went wrong with opening your money bag. " +
                errorText
              }
              modalTitleColor={"#C0C3FF"}
              modalIcon={iosWalletImg}
              buttonText={"Return to Telegram"}
            />
          </>
        );
      }}
    </QueryWrapper>
  );
};
