import { FC } from "react";
import { observer } from "mobx-react-lite";

import Coin from "@/assets/capybara-images/coin.svg?react";
import { Button } from "@/components/Button";

import styles from "./styles.module.sass";
import { DialogComponents } from "@/components/Dialog";
import { openExternalUrl } from "@/utils/handleExpandPage";

type MoneyBagTipProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAction: VoidFunction;
  onUserGuide: VoidFunction;
};

export const MoneyBagNotEnouhgCoins: FC<MoneyBagTipProps> = observer(
  ({ isOpen, onClose, onAction, onUserGuide }) => {
    return (
      <DialogComponents.Container
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
        isOpen={Boolean(isOpen)}
        onAction={onAction}
        onClose={onClose}
      >
        {({ handleClose }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              className={styles.modalCloser}
              onClick={handleClose}
            ></DialogComponents.Closer>
            <div className={styles.capybag}>
              <Coin width={40} height={40} />
            </div>
            <DialogComponents.TextContainer className={styles.textContainer}>
              <DialogComponents.Title className={styles.modalTitle}>
                {`Not Enough Coins to Mint!`}
              </DialogComponents.Title>
              <DialogComponents.Text className={styles.modalText}>
                <span style={{ lineHeight: "20px" }}>
                  {`It looks like you don’t have enough coins to mint a Money Bag. Earn more coins by:`}
                  <span>Tapping on a Capybara 🐾</span>
                  <span>Completing tasks ✅ Referring friends</span>
                  {` You can also buy Money Bags directly from other users on TradePort.xyz`}
                </span>
              </DialogComponents.Text>
            </DialogComponents.TextContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Button
                onClick={() => {
                  openExternalUrl("https://tradeport.xyz/");
                }}
                label="Buy Money Bag"
                styleType="primary"
                block={true}
              ></Button>
              <Button
                onClick={onUserGuide}
                label="How to Buy User Guide"
                styleType="outline"
                block={true}
              ></Button>
            </div>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
    );
  }
);
