import { Transaction } from "@mysten/sui/transactions";
import { bcs } from "@mysten/bcs";
import { bech32 } from "bech32";
import { SIGNATURE_FLAG_TO_SCHEME } from "@mysten/sui/cryptography";
import {
  KioskClient,
  KioskItem,
  KioskOwnerCap,
  KioskTransaction,
} from "@mysten/kiosk";
import { SuiClient } from "@mysten/sui/client";

// region gamecard
export const buildMintNftTx = ({
  packageAddress,
  registryAddress,
  signature,
  league,
  points,
  sigValidUntil,
}: {
  packageAddress: string;
  registryAddress: string;
  sigValidUntil: number;
  league: number;
  points: number;
  signature: Uint8Array;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::mint`,
    arguments: [
      tx.object(registryAddress),
      tx.object("0x6"), // Clock object
      tx.pure.u64(sigValidUntil),
      tx.pure.option("u64", points),
      tx.pure.option("u64", league),
      tx.pure.vector("u8", signature),
    ],
  });
  tx.setGasBudget(50000000);
  return tx;
};

export const buildCheckinArgs = ({
  league,
  validUntil,
  points,
  user,
}: {
  league?: number;
  points?: number;
  validUntil: number;
  user: string;
}): Uint8Array => {
  const bcsS = bcs.struct("CheckinArgTBS", {
    validUntil: bcs.u64(),
    points: bcs.option(bcs.u64()),
    league: bcs.option(bcs.u64()),
    user: bcs.string(),
  });

  const checkinStruct = bcsS.serialize({
    validUntil,
    points,
    league,
    user,
  });

  return checkinStruct.toBytes();
};

export const buildCheckinTx = ({
  functionParams,
  packageAddress,
  userAddress,
}: {
  functionParams: {
    nftAddress: string;
    treasury: string;
    registry: string;
    fee: number;
    validUntil: number;
    points?: number;
    league?: number;
    signature: Uint8Array;
  };
  packageAddress: string;
  userAddress: string;
}) => {
  const tx = new Transaction();
  const [fee] = tx.splitCoins(tx.gas, [functionParams.fee]);
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::checkin`,
    arguments: [
      tx.object(functionParams.nftAddress),
      tx.object(functionParams.treasury),
      tx.object(functionParams.registry),
      tx.object("0x6"), // Clock object
      fee,
      tx.pure.u64(functionParams.validUntil),
      tx.pure.option("u64", functionParams.points),
      tx.pure.option("u64", functionParams.league),
      tx.pure.vector("u8", functionParams.signature),
    ],
  });
  tx.transferObjects([fee], userAddress);
  tx.setGasBudget(10000000);
  return tx;
};

export const buildGrandTreasuryTx = ({
  adminCap,
  registry,
  packageAddress,
}: {
  adminCap: string;
  registry: string;
  packageAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::grant_treasurer_cap`,
    arguments: [tx.object(adminCap), tx.object(registry)],
    typeArguments: [`${packageAddress}::capybara_portal::CAPYBARA_PORTAL`],
  });
  tx.setGasBudget(10000000);
  return tx;
};

export const buildSpendTransaction = ({
  amount,
  registry,
  nftId,
  packageAddress,
}: {
  registry: string;
  nftId: string;
  amount: number;
  packageAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_game_card::spend`,
    arguments: [tx.object(registry), tx.object(nftId), tx.pure.u64(amount)],
    typeArguments: [`${packageAddress}::capybara_portal::CAPYBARA_PORTAL`],
  });
  tx.setGasBudget(10000000);
  return tx;
};
// endregion
//region lootbox
export const getCap = async (kioskClient: KioskClient, address: string) => {
  const { kioskOwnerCaps } = await kioskClient.getOwnedKiosks({ address });
  // Assume that the user has only 1 kiosk.
  // Here, you need to do some more checks in a realistic scenario.
  // And possibly give the user in our dApp a kiosk selector to choose which one they want to interact with (if they own more than one).

  return kioskOwnerCaps.find((el) => el.isPersonal);
};
export const getKioskWithNFT = async (
  kioskClient: KioskClient,
  address: string
) => {
  const { kioskOwnerCaps } = await kioskClient.getOwnedKiosks({ address });
  // Assume that the user has only 1 kiosk.
  // Here, you need to do some more checks in a realistic scenario.
  // And possibly give the user in our dApp a kiosk selector to choose which one they want to interact with (if they own more than one).

  return kioskOwnerCaps[0];
};
// endregion
//region lootbox
export const buildMintLootBoxTx = async ({
  dataStorage,
  uid,
  signature,
  packageAddress,
  validUntil,
  count,
  kioskClient,
  kioskOwnerCap,
}: {
  dataStorage: string;
  uid: bigint;
  validUntil: number;
  count: number;
  signature: Uint8Array;
  packageAddress: string;
  kioskClient: KioskClient;
  kioskOwnerCap: string;
}) => {
  const tx = new Transaction();
  const existingKiosk = await getCap(kioskClient, kioskOwnerCap);
  let kioskTx: KioskTransaction = new KioskTransaction({
    kioskClient,
    transaction: tx,
    cap: existingKiosk,
  });
  if (!existingKiosk) {
    kioskTx = new KioskTransaction({ kioskClient, transaction: tx });
    kioskTx.createPersonal(true);
  }

  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::mint_lootbox`,
    arguments: [
      tx.object(dataStorage),
      tx.object("0x6"), // Clock object
      tx.pure.u64(uid),
      tx.pure.u64(validUntil),
      tx.pure.u64(count),
      tx.pure.vector("u8", signature),
      tx.object(kioskTx.getKiosk()),
      tx.object(kioskTx.getKioskCap()),
    ],
  });

  kioskTx.finalize();
  if (count > 1) {
    const base = 20000000;

    tx.setGasBudget(base * count);
  } else {
    tx.setGasBudget(30000000);
  }

  return tx;
};

export const buildOpenLootBoxTx = async ({
  dataStorage,
  packageAddress,
  transferLootboxId,
  emptyTp,
  kioskClient,
  userAddress,
}: {
  dataStorage: string;
  packageAddress: string;
  transferLootboxId: string;
  emptyTp: string;
  userAddress: string;
  kioskClient: KioskClient;
}) => {
  const suiRandomAddress = "0x8";
  const tx = new Transaction();

  const existingKiosk = await getCap(kioskClient, userAddress);
  if (!existingKiosk) {
    throw new Error("No kiosk found");
  }

  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::open_lootbox`,
    arguments: [
      tx.object(dataStorage),
      tx.object(suiRandomAddress),
      tx.object(existingKiosk.kioskId),
      tx.object(existingKiosk.objectId),
      tx.pure.id(transferLootboxId),
      tx.object(emptyTp),
    ],
  });

  tx.setGasBudget(10000000);
  return tx;
};

export const buildAdminMintFruitTx = ({
  fruitTypes,
  lootboxAdminCap,
  dataStorage,
  packageAddress,
}: {
  lootboxAdminCap: string;
  dataStorage: string;
  fruitTypes: Array<string>;
  packageAddress: string;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::mint_fruit_by_admin`,
    arguments: [
      tx.object(lootboxAdminCap),
      tx.object(dataStorage),
      tx.pure.vector("string", fruitTypes),
    ],
  });
  tx.setGasBudget(10000000);
  return tx;
};

export const buildExchangeCapybaraTx = ({
  packageAddress,
  dataStorage,
  fruits,
}: {
  packageAddress: string;
  dataStorage: string;
  fruits: Array<string>;
}) => {
  const tx = new Transaction();
  tx.moveCall({
    target: `${packageAddress}::capybara_lootbox::exchange_fruits_to_capybara`,
    arguments: [
      tx.object(dataStorage),
      ...fruits.map((fruit) => tx.object(fruit)),
    ],
  });

  tx.setGasBudget(10000000);
  return tx;
};
// endregion
export function decodeSuiPrivateKey(value: string) {
  const { words } = bech32.decode(value);

  const extendedSecretKey = new Uint8Array(bech32.fromWords(words));
  const secretKey = extendedSecretKey.slice(1);
  const signatureScheme =
    SIGNATURE_FLAG_TO_SCHEME[
      extendedSecretKey[0] as keyof typeof SIGNATURE_FLAG_TO_SCHEME
    ];
  return {
    schema: signatureScheme,
    secretKey: secretKey,
  };
}

export async function getPublisherObject(
  client: SuiClient,
  owner: string
): Promise<string> {
  const res = await client.getOwnedObjects({
    filter: {
      StructType: "0x2::package::Publisher",
    },
    owner: owner,
  });
  const publisherObj = res.data[0].data?.objectId;

  return publisherObj ?? "";
}

export const getAllKiosks = async ({
  kioskClient,
  owner,
  cursor,
}: {
  kioskClient: KioskClient;
  owner: string;
  cursor?: string;
}): Promise<Array<KioskOwnerCap>> => {
  const { kioskOwnerCaps, hasNextPage, nextCursor } =
    await kioskClient.getOwnedKiosks({
      address: owner,
      pagination: {
        cursor,
      },
    });
  if (hasNextPage) {
    const nextKiosks = await getAllKiosks({
      kioskClient,
      owner,
      cursor: nextCursor ?? undefined,
    });
    return [...kioskOwnerCaps, ...nextKiosks];
  } else {
    return kioskOwnerCaps;
  }
};

export const getAllObjectsOwnedByKiosks = async ({
  kiosks,
  kioskClient,
  nftType,
}: {
  kiosks: Array<KioskOwnerCap>;
  kioskClient: KioskClient;
  nftType?: string;
}) => {
  const resultArray: Array<[KioskOwnerCap, Array<KioskItem>]> = [];

  for (const kiosk of kiosks) {
    const { items } = await kioskClient.getKiosk({
      id: kiosk.kioskId,
      options: {
        objectOptions: {
          showBcs: true,
          showPreviousTransaction: true,
          showType: true,
          showOwner: true,
          showStorageRebate: true,
        },
        withObjects: true,
      },
    });
    if (nftType) {
      const matchedItems = items.filter(
        (item) => item.data && item.data.type === nftType && !item.listing
      );
      if (matchedItems.length > 0) {
        resultArray.push([kiosk, matchedItems]);
      }
    } else {
      resultArray.push([kiosk, items]);
    }
  }

  return resultArray;
};

export const getAllKiosksAndObjects = async ({
  kioskClient,
  owner,
}: {
  kioskClient: KioskClient;
  owner: string;
}) => {
  const kiosks = await getAllKiosks({
    kioskClient,
    owner,
  });

  return getAllObjectsOwnedByKiosks({
    kiosks,
    kioskClient,
    nftType: `0x865673ec50d3b0dfc4d8cc8c9a8710287f45a035cf136a1ab4e6274a5fb56e78::capybara_lootbox::NFTLootbox`,
  });
};
