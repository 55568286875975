import rootStore, { gameStore, leagueStore, walletStore } from "@/store";
import { balance } from "./format";

const overflow = 100;

export const handleExpandPage = () => {
  document.body.style.overflowY = "hidden";
  document.body.style.marginTop = `${overflow}px`;
  document.body.style.height = `${window.innerHeight + overflow}px`;
  document.body.style.paddingBottom = `${overflow}px`;
  window.scrollTo(0, overflow);
};

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isAndroid = /Android/.test(navigator.userAgent);

export const openExternalUrl = (url: string) => {
  if (isIOS) {
    Telegram.WebApp.openLink(url,{
      try_instant_view: false
    });
  } else {
    window.open(url, "_blank");
  }
};

/**
 * Генерирует URL с параметрами на основе заданного пути и объекта параметров.
 * @param path - Путь на сайте, например "/StashedNftUpdate"
 * @param params - Объект с параметрами для добавления в URL
 * @returns Сгенерированный URL с закодированными параметрами
 */
export const externalLink = (
  path:
    | "/auth"
    | "/authWallets"
    | "/authStashed"
    | "/transferStashed"
    | "/StashedNftMint"
    | "/StashedNftUpdate"
    | "/MintMoneyBag"
    | "/nftMint"
    | "/OpenMoneyBag"
    | "/transferOKX",
  params: Record<string, string> = {}
) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}${path}`;
  const url = new URL(baseUrl);

  Object.entries({ ...params, userId: rootStore.userData.id }).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, String(value));
    }
  });

  openExternalUrl(url.toString());
};

export const handleMint = ({
  callback,
  navigate,
  isCheckIn,
}: {
  callback: (isError: boolean) => void;
  navigate: (path: string) => void;
  isCheckIn?: boolean;
}) => {
  if (walletStore.isCreated) {
    if (
      walletStore.nft === null ||
      walletStore.nft?.owner === walletStore.address
    ) {
      if (walletStore.balance) {
        if (+balance(walletStore.balance) < 0.01) {
          callback(true);
        } else {
          if (isCheckIn) {
            if (walletStore.walletType === "OKX") {
              navigate("/MintingNftOKX");
            } else {
              localStorage.setItem("nftMint", "true");

              externalLink("/nftMint", {
                earn: gameStore.balance.toString(),
                leagueName: leagueStore.leagueName,
                reciever: walletStore?.address ?? "null",
                token: localStorage.getItem(`token`) ?? "null",
                nftId: walletStore.nft?.address ?? "null",
                level: leagueStore.level.toString(),
                coin: gameStore.balance.toString(),
                league: leagueStore.level.toString(),
                nftOwner: walletStore.nft?.owner ?? "null",
                updatedAt: walletStore.nft?.updatedAt.toString() ?? "null",
              });
            }
          } else {
            if (walletStore.walletType === "OKX") {
              navigate("/MintingNftOKX");
            } else {
              localStorage.setItem("nftMint", "true");

              externalLink("/nftMint", {
                earn: gameStore.balance.toString(),
                leagueName: leagueStore.leagueName,
                reciever: walletStore?.address ?? "null",
                token: localStorage.getItem(`token`) ?? "null",
                level: leagueStore.level.toString(),
                nftId: walletStore.nft?.address ?? "null",
                coin: gameStore.balance.toString(),
                league: leagueStore.level.toString(),
              });
            }
          }
        }
      }
    } else {
      navigate("/?showWalletError=true");
    }
  } else {
    navigate("/?fromTask=true");
  }
};
