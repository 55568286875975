import GameStore from "./GameStore";
import { observable, flow, action } from "mobx";
import { backend } from "@/services";
import {
  delay,
  formatNumber,
  isDevelopment,
  isProduction,
  isTelegram,
  isWeb,
} from "@/utils";

import i18next from "i18next";
import ToastManagerStore from "./ToastManager";
import StoryManagerStore from "./StoryManager";
import { isProdUrl, stories, whitelist } from "@/constants";
import WalletStore from "./WalletStore";
import { lootboxesStore, missionStore } from ".";
import { SuccessToast } from "./Mission";
import coinImg from "@/assets/icons/coin.svg";
import { connectWallet } from "@/utils/createWallet";
import { OKXSuiProvider } from "@okxconnect/sui-provider";
import { TelegramUser } from "@/services/types";
import { initSentry } from "@/utils/sentry";

const bot = isProduction ? 'getcapybot' : 'Kapybaradev_bot';

const flags = {
  avro: false,
  avroSchemas: false,
  objects: false,
  auth: false,
  ws: false,
  wsAuth: false,
  wsSync: false,
  initOptions: false,
  initState: false,
};

export type HapticType =
  | "off"
  | "light"
  | "medium"
  | "heavy"
  | "rigid"
  | "soft";

export default class RootStore {
  @observable public accessor loading: boolean = true;
  @observable public accessor error: boolean = false;
  public accessor initFlags: typeof flags = flags;
  public accessor initFlagsError: undefined | typeof flags = undefined;
  public accessor errorMessage = "";
  public accessor errorInInit = false;

  @observable.ref public accessor gameStore: GameStore;
  @observable.ref public accessor walletStore: WalletStore;
  @observable.ref public accessor toastManagerStore: ToastManagerStore;
  @observable.ref public accessor storyManagerStore: StoryManagerStore;
  @observable public accessor showWallet: boolean = false;

  @observable public accessor qr: boolean = false;
  @observable public accessor gameReady: boolean = false;
  @observable public accessor isEarly: boolean = false;

  @observable public accessor googleHash: string | undefined;

  @observable public accessor lang: string = i18next.language;
  @observable public accessor hapticType: HapticType = "soft";
  @observable public accessor isUserInWhiteList: boolean = false;

  @observable.ref public accessor userData: TelegramUser = {
    auth_date: Telegram.WebApp.initDataUnsafe.auth_date,
    first_name: Telegram.WebApp.initDataUnsafe.user?.first_name ?? '',
    hash: Telegram.WebApp.initDataUnsafe.hash,
    id: Telegram.WebApp.initDataUnsafe.user?.id ?? 0,
    photo_url: Telegram.WebApp.initDataUnsafe.user?.photo_url ?? '',
    username: Telegram.WebApp.initDataUnsafe.user?.username ?? '',
  }

  @observable public accessor loginStarted: boolean = false;

  constructor() {
    this.gameStore = new GameStore(this);
    this.walletStore = new WalletStore(this);
    this.toastManagerStore = new ToastManagerStore(this);
    this.storyManagerStore = new StoryManagerStore(this);
  }

  @action.bound addErrorHandlers() {
    window.addEventListener("error", (event) => {
      if (
        event?.filename?.startsWith("debugger://") || 
        event?.filename?.startsWith("chrome-extension://") ||
        event?.filename?.startsWith("moz-extension://")
      ) {
        return;
      }

      if (event?.filename?.startsWith(window.location.origin)) {
        this.error = true;

        if (!this.initFlagsError) {
          this.initFlagsError = Object.assign({}, this.initFlags);
        }
        if (!this.errorMessage) {
          this.errorMessage = event.error.message;
        }
      }
    });
    window.addEventListener("unhandledrejection", (event) => {
      if (event.reason?.stack?.includes(window.location.origin)) {
        this.error = true;
        if (!this.initFlagsError) {
          this.initFlagsError = Object.assign({}, this.initFlags);
        }
        if (!this.errorMessage) {
          this.errorMessage = event.reason.message;
        }
      }
    });
  }

  @action.bound initTelegram() {
    if (isWeb) return;

    Telegram.WebApp.expand();
    Telegram.WebApp.disableVerticalSwipes();
  }

  @action.bound setLang(lang: string) {
    this.lang = lang;
  }

  @action.bound setHapticType(type: HapticType) {
    this.hapticType = type;
  }

  @action.bound setLoading(value: boolean) {
    this.loading = value;
  }

  @action.bound setGoogleHash(value: string) {
    this.googleHash = value;
  }

  @action.bound setUserData(user: Partial<TelegramUser>) {
    this.userData = {...this.userData, ...user};
  }

  @action.bound setLoginStarted (value: boolean) {
    this.loginStarted = value;
  }

  @action.bound updateNftData() {
    const itemCheckIn = missionStore.getById(1003);
    const itemMint = missionStore.getById(1002);

    setTimeout(() => {
      backend
        .resync()
        .then((state) => {
          this.walletStore.setNftData(state.nft);
          this.gameStore.initState(state);
          lootboxesStore.setOpenBagsCount(state.openBagsCount);
          if (itemMint?.isDone) {
            const toastData: SuccessToast = {
              text: `+${formatNumber(itemCheckIn?.reward ?? 0)}`,
              icon: coinImg,
            };

            itemCheckIn?.check(true, toastData);
          } else {
            const toastData: SuccessToast = {
              text: `+${formatNumber(itemMint?.reward ?? 0)}`,
              icon: coinImg,
            };
            itemMint?.check(true, toastData);
          }
        })
        .catch((e) => {
          throw new Error(e);
        });
    }, 2000);
  }

  @action.bound setShowWallet(value: boolean) {
    this.showWallet = value;
  }

  @flow.bound *init() {
    if (isProduction || isProdUrl) {
      initSentry(this.userData.id);
    }

    this.addErrorHandlers();
    i18next.on("languageChanged", this.setLang);
    stories.forEach((storySet) => this.storyManagerStore.addStorySet(storySet));
    console.groupCollapsed("init with", backend.constructor.name);
    try {
      stories.forEach((storySet) =>
        this.storyManagerStore.addStorySet(storySet)
      );

      if (isProduction) {
        const p = Telegram.WebApp.platform;
        if (["tdesktop"].includes(p)) {
          this.qr = true;
          return;
        }
      }

      const animationDelay = isDevelopment ? delay(0) : delay(4000);
      const loadTimeout = setTimeout(() => {
        console.groupEnd();
        this.errorInInit = true;
        throw new Error("Game load timeout");
      }, 20_000);

      if (isTelegram) {
        this.initTelegram();
        if (isProduction) {
          this.isUserInWhiteList = whitelist.includes(this.userData.id);
        } else {
          this.isUserInWhiteList = true;
        }
      } else {
        if (!isProduction) {
          this.isUserInWhiteList = true;
        }
      }

      yield delay(0); // macrotask for first frame render
      Telegram.WebApp.ready();

      yield backend.init();
      const options = backend.options;
      const state = backend.initState;
      if (options && state) {
        yield this.gameStore.init(options, state);
        this.isEarly = state.isEarly;

        lootboxesStore.setOpenBagsCount(state.openBagsCount);

        if (state.nft) {
          console.log("nft", state.nft);
          this.walletStore.setNftData(state.nft);
        }

        if (state.wallet && state.walletType) {
          console.log(123);

          if (state.walletType === "OKX") {
            console.log("OKX state", state.wallet);
            connectWallet()
              .then((result) => {
                if (result.connected()) {
                  const suiProvider = new OKXSuiProvider(result);

                  this.walletStore.setOKXProviders(suiProvider, result);
                  this.walletStore.setWallet(
                    "OKX",
                    suiProvider.getAccount()?.address
                  );
                } else {
                  this.walletStore.logout();
                }
                this.showWallet = false;
                this.loading = false;
              })
              .catch((e) => {
                this.loading = false;
                console.log("OKX error", e);
                this.walletStore.logout();
              });
          } else if (state.walletType === "STASHED") {
            this.showWallet = false;
            this.loading = false;
            localStorage.setItem("stashed:recentAddress", state.wallet);
            this.walletStore.setWallet("STASHED", state.wallet);

            localStorage.setItem(
              "sui-dapp-kit:wallet-connection-info",
              JSON.stringify({
                state: {
                  lastConnectedWalletName: "Stashed",
                  lastConnectedAccountAddress: state.wallet,
                },
                version: 0,
              })
            );
          }
        } else {
          this.loading = false;
          this.showWallet = true;
        }
      }

      this.gameReady = true;
      console.log("game ready");
      yield animationDelay;

      clearTimeout(loadTimeout);
      console.groupEnd();
    } catch (e) {
      console.groupEnd();
      this.errorInInit = true;
      throw e;
    }
  }

  @action.bound public async startLogin () {
    const response = await backend.startLogin();
    this.setLoginStarted(true);
    return { link: `https://t.me/${bot}?start=auth_${response.uid}`, uid: response.uid };
  }

  @action.bound public async checkLogin (uid: string) {
    const response = await backend.checkLogin(uid);

    if (!response.token) return;

    backend.setLoginToken(response.token);
    this.setUserData(response.user);
  }
}
