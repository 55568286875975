import React, { ElementType } from "react";
import styles from "./styles.module.css";
import { NavLink } from "react-router-dom";

type componentsType = {
  Icon?: ElementType;
  text?: string;
  component: ElementType;
  path: string;
};

interface MenuProps {
  components: Array<componentsType>;
  onSelect: () => void;
}

export const Menu: React.FC<MenuProps> = ({
  components,
  onSelect
}) => {
  return (
    <div className={styles.menuContainer}>
      <div className={styles.menu}>
        {components.map(
          ({ Icon, text, path }, index) =>
            Icon &&
            text && (
              <NavLink
                to={path}
                onClick={onSelect}
                style={{
                  padding: index === 3 ? "0 9px" : undefined,
                }}
                className={({ isActive }) => `${styles.menuItem} ${
                  isActive ? styles.selectedItem : ""
                }`}
                key={text}
              >
                <Icon />
                <span>{text}</span>
              </NavLink>
            )
        )}
      </div>
    </div>
  );
};
