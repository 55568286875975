export const isDevelopment = import.meta.env.MODE == "development";
export const isProduction = import.meta.env.MODE == "production";
export const isStage = import.meta.env.MODE == "stage";
export const isTelegram = Boolean(Telegram.WebApp.initData);
export const isWeb = !isTelegram && ["macos", "weba", "web", "unknown"].includes(Telegram.WebApp.platform);
export const isBackend =
  isProduction ||
  isTelegram ||
  (isWeb && isStage) ||
  localStorage["devToken"] && localStorage["devToken"] !== "null";
