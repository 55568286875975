/* eslint-disable react-hooks/exhaustive-deps */
// import { TelegramUser } from "@/services/types";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import store from "@/store";

import styles from './styles.module.sass';

import bg from '@/assets/layout/login/bg.webp';
import { useNavigate } from "react-router-dom";
import { backend } from "@/services";
import { Button } from "@/components/Button";
import { isDevelopment } from "@/utils";

export const Login = observer(() => {
  const navigate = useNavigate();
  const [link, setLink] = useState('');
  const linkRef = useRef<HTMLAnchorElement>(null);

  const startLogin = async () => {
    const { link: botLink, uid } = await store.startLogin();
    setLink(botLink);
    setTimeout(() => linkRef.current?.click(), 500);

    await store.checkLogin(uid);
    store.init();
    navigate('/');
  }

  const updateToken = async () => {
    const token = localStorage.getItem("token") ?? "";

    if (!token || isDevelopment) return;

    if (token) {
      localStorage.removeItem("token");
      store.setLoading(true);
      backend.setLoginToken(token);
      const response = await backend.refreshToken();
      store.setUserData(response.user || {});
      store.init();
      navigate('/');
    }
  };

  useEffect(() => {
    if (backend.hasToken) {
      return navigate("/");
    } else {
      updateToken();
    }
  }, [navigate]);

  return (
    <div id='tg-login' className={styles.login}>
        <img src={bg}/>

        <div className={styles.controls}>
          <Button
            onClick={startLogin}
            label={store.loginStarted ? "Please wait..." : "Login via Telegram Bot"}
            styleType="primary"
            block
          />
          <a
            style={{visibility: link ? 'visible' : 'hidden'}}
            ref={linkRef} href={link}
            target="_blank">
              Click here if not redirected
          </a>
        </div>
    </div>
  )
});