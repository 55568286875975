import styles from "./styles.module.css";
import { CoinsDisplay } from "@/components/CoinsDisplay";
import { tap, click } from "@/components/Capybara";
import { observer } from "mobx-react-lite";
import EnergyBar from "@/components/EnergyBar";
import {
  boostTapStore,
  gameStore,
  leagueStore,
  lootboxesStore,
  walletStore,
} from "@/store";
import Rocket from "@/components/Rocket";
import { capybaraLevels } from "@/constants";
import { CreateWallet } from "@/components/CreateWallet/CreateWallet";
import { Wallet } from "@/components/Wallet";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import ExternalLink from "@/assets/icons/arrow-square-out.svg?react";
import { TutorialOverlay } from "./TutorialOverlay";
import { CapybaraNftCard } from "@/components/CapybaraNftCard";
import { useLocation, useNavigate } from "react-router-dom";
import { Bag } from "./Bag";
import { useEffect, useState } from "react";
import { StashedProvider } from "@/components/StashedProvider";
import { Dialog } from "@/components/Dialog";
import { PromoMoneyBag } from "@/components/PromoMoneyBag";
import { PromoWebApp } from "@/components/PromoWebApp";
import { isDevelopment, isStage, isWeb } from "@/utils";

const externalUrl = isDevelopment ? 'http://localhost:5173' : isStage ? 'https://app.kapybara.xyz' : '';

export const Feed = observer(() => {
  const { level } = leagueStore;
  const { balance, totalBalance, promoWebAppDialog, setPromoWebAppDialog } = gameStore;
  const [isOpenWalletError, setIsOpenWalletError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onPromoWebAppAction = () => {
    setPromoWebAppDialog(false);

    setTimeout(() => {
      Telegram.WebApp.openLink(`${externalUrl}/?${Telegram.WebApp.initData}`);
    }, 300);
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (
      walletStore.nft &&
      walletStore.address &&
      (!sessionStorage.getItem("walletError") || query.has("showWalletError"))
    ) {
      if (walletStore.address !== walletStore.nft?.owner) {
        sessionStorage.setItem("walletError", "true");

        console.log("walletError", walletStore.address);

        setIsOpenWalletError(true);
      }
    }
  }, [walletStore.address, location]);

  return (
    <div className={styles.feedContainer}>
      {boostTapStore.isRocket && <Rocket />}
      <div className={styles.coinsBlockWrapper}>
        {!isWeb && <button onClick={() => setPromoWebAppDialog(true)} className={styles.webAppAction}><ExternalLink />Get Ready for the BIG EVENT</button>}
        {isWeb && <>
          {
            walletStore.isCreated ? (
              <Wallet
                backgroundColor={capybaraLevels[level].walletBackgroundColor}
              />
            ) : (
              <CreateWallet
                backgroundColor={capybaraLevels[level].walletBackgroundColor}
              />
            )}
          </>}
        <div className={styles.coins}>
          <CoinsDisplay />
          {walletStore.address && isWeb && (
            <>
              <Bag onClick={() => navigate("/buyMoneyBag")} />
            </>
          )}
        </div>
      </div>
      <div className={styles.capybaraContainer}>
        <StashedProvider>
          <CapybaraNftCard />
        </StashedProvider>
        <EnergyBar />
      </div>
      <Dialog
        modalWallpaper={walletBg}
        isOpen={isOpenWalletError}
        onClose={() => {
          setIsOpenWalletError(false);
          navigate("/");
        }}
        onAction={() => {
          setIsOpenWalletError(false);
          navigate("/");
        }}
        title={"Warning, incorrect wallet"}
        text={
          "You have logged in with a different wallet. No actions can be performed with NFT. Please log in with the correct wallet."
        }
        modalTitleColor={"#C0C3FF"}
        modalIcon={iosWalletImg}
        buttonText={"Continue"}
      />
      <TutorialOverlay
        isNewbie={!balance && !totalBalance}
        onClick={click}
        onTap={tap}
      />

      {walletStore.isCreated && isWeb && (
        <PromoMoneyBag
          isOpen={lootboxesStore.promoModal}
          onClose={() => {
            lootboxesStore.setShowPromo();
          }}
          onAction={() => {
            navigate("/buyMoneyBag");
            lootboxesStore.setShowPromo();
          }}
        />
      )}

      <PromoWebApp isOpen={promoWebAppDialog} onAction={onPromoWebAppAction} onClose={() => setPromoWebAppDialog(false)}/>
    </div>
  );
});
