import { observer } from "mobx-react-lite";
import store, { gameStore } from "@/store";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import { isDevelopment, isProduction, isStage, isTelegram, isWeb } from "@/utils";
import clsx from "clsx";
import "@mysten/dapp-kit/dist/index.css";
import "swiper/css/pagination";
import "swiper/css";
import Loader from "@/layout/Loader";
import LockScreen from "./layout/LockScreen";
import ErrorHint from "@/components/ErrorHint";
import DevOverlay from "./layout/DevOverlay";
import TelegramHeaderManager from "./components/TelegramHeaderManager";
import ToastContainer from "./components/ToastContainer/ToastContainer";
import CapybaraLayout from "./layout/CapybaraLayout";
import { menuItems } from "./constants";
import { LeagueLevelUpModal } from "./layout/LevelUpModal";
import Story from "./layout/Story";
import WalletLayout from "./layout/WalletLayout";
import WalletAuthLayout from "./layout/WalletAuthLayout";
import { AuthWalletsFlow } from "./components/AuthWallets";
import { StashedAuth } from "./components/CreateWallet/components/StashedAuth";
import WalletTransfer from "./views/WalletTransfer";
import { StashedTransfer } from "./components/CreateWallet/components/StashedTransfer";
import { AutoFeedBotEarnedModal } from "./views/Boost/Modals/AutoFeedBotEarned";
import WalletBuy from "./views/WalletBuy";
import WalletPage from "./views/WalletPage";
import { Collections } from "./views/Collections";
import { BuyMoneyBag } from "./views/BuyMoneyBag";
import { EnokiProvider } from "./components/EnokiProvider";
import { StashedProvider } from "./components/StashedProvider";
import { Explore } from "./views/Earn/Explore";
import { StashedNftMint } from "./components/CreateWallet/components/StashedNftMint";
import { StashedNftUpdate } from "./components/CreateWallet/components/StashedNftUpdate";
import SelectWallet from "./views/SelectWallet";
import { MintingNftOKX } from "./components/MintingNftOKX";
import { MintingNft } from "./components/MintingNft";
import { Auth } from "./components/CreateWallet/components/Auth";
import { StashedMintingMoneyBag } from "./components/CreateWallet/components/StashedMintingMoneyBag";
import { StashedOpeningMoneyBag } from "./components/CreateWallet/components/StashedOpeningMoneyBag";
import { SuiClientProvider } from "@mysten/dapp-kit";
import { networkConfig } from "./components/StashedProvider/networkConfig";
import { Login } from "./views/Login/Login";
import { useEffect } from "react";
import { backend } from "./services";
import { TelegramUser } from "./services/types";

const App = observer(() => {
  const className = clsx("appLayout", { ["isDevelopment"]: isDevelopment });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const authUser = async (auth: string) => {
    await backend.auth(auth);
    navigate('/login');
  }

  const initApp = async () => {
    backend.setDevToken();
    const auth = window.location.search.slice(1, window.location.search.length);

    if (isWeb && !backend.hasToken && (isProduction || isStage)) {
      // web > tg flow
      if (!auth) return navigate('/login');
      
      // tg > web flow
      const authData = Object.fromEntries([...searchParams.entries()]) as {user: string; hash: string};
      const user = JSON.parse(authData.user) as TelegramUser;
      store.setUserData({...user, hash: authData.hash});
      await authUser(auth);
    }

    store.init();
    navigate("/");
  }

  useEffect(() => {
    initApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (window.location.pathname === "/auth") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <EnokiProvider>
            <Auth />
          </EnokiProvider>
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  // if (window.location.pathname === "/transferOKX") {
  //   return (
  //     <WalletAuthLayout>
  //       <StashedProvider>
  //         <EnokiProvider>
  //           <StashedAuthAndTransferOKX />
  //         </EnokiProvider>
  //       </StashedProvider>
  //     </WalletAuthLayout>
  //   );
  // }

  if (window.location.pathname === "/authWallets") {
    return (
      <WalletAuthLayout>
        <EnokiProvider>
          <AuthWalletsFlow />
        </EnokiProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/authStashed") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedAuth />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/transferStashed") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedTransfer />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/MintMoneyBag") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedMintingMoneyBag />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/OpenMoneyBag") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedOpeningMoneyBag />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/StashedNftMint") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedNftMint />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/StashedNftUpdate") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <StashedNftUpdate />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/nftMint") {
    return (
      <WalletAuthLayout>
        <StashedProvider>
          <MintingNft />
        </StashedProvider>
      </WalletAuthLayout>
    );
  }

  if (window.location.pathname === "/login") {
    return <div className={className}><Login /></div>
  }

  return (
    <div className={className}>
      {isTelegram && <TelegramHeaderManager />}
      {store.error && <ErrorHint />}
      {store.qr && <LockScreen />}
      {store.loading && !store.qr && <Loader />}
      {/* {!rootStore.isUserInWhiteList && <ImproveScreen />} */}
      {store.gameReady && (
        <>
          {!isProduction && gameStore.devOverlay && <DevOverlay />}
          <ToastContainer />
          <Story />
          <LeagueLevelUpModal />
          <AutoFeedBotEarnedModal />
          <Routes>
            <Route
              key={"wallet"}
              path="/wallet"
              element={
                <StashedProvider>
                  <WalletLayout>
                    <WalletPage />
                  </WalletLayout>
                </StashedProvider>
              }
            />
            <Route
              key={"WalletTransfer"}
              path="/WalletTransfer"
              element={
                <StashedProvider>
                  <WalletLayout>
                    <WalletTransfer />
                  </WalletLayout>
                </StashedProvider>
              }
            />
            <Route
              key={"WalletBuy"}
              path="/WalletBuy"
              element={
                <StashedProvider>
                  <WalletLayout>
                    <WalletBuy />
                  </WalletLayout>
                </StashedProvider>
              }
            />

            <Route
              key={"Explore"}
              path="/explore/:id"
              element={
                <CapybaraLayout isHideMenu={false}>
                  <Explore />
                </CapybaraLayout>
              }
            />

            <Route
              key={"Collections"}
              path="/collections"
              element={
                <CapybaraLayout>
                  <Collections />
                </CapybaraLayout>
              }
            />

            <Route
              key={"BuyMoneyBag"}
              path="/buyMoneyBag"
              element={
                <SuiClientProvider
                  networks={networkConfig}
                  defaultNetwork="mainnet"
                >
                  <BuyMoneyBag />
                </SuiClientProvider>
              }
            />

            <Route
              key={"BuyMoneyBag"}
              path="/selectWallet"
              element={<SelectWallet />}
            />

            <Route
              key={"MintingNftOKX"}
              path="/MintingNftOKX"
              element={
                <CapybaraLayout isHideMenu={true}>
                  <MintingNftOKX />
                </CapybaraLayout>
              }
            />

            {menuItems.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={
                  <CapybaraLayout>
                    <item.component />
                  </CapybaraLayout>
                }
              />
            ))}
          </Routes>
        </>
      )}
    </div>
  );
});

export default App;
