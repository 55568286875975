import { SignType } from "@/components/CreateWallet/components/QueryWrapper";
import {
  apiProduction,
  apiStage,
  DataStorage,
  EMPTY_TP,
  isProdUrl,
  PACKAGE_ADDRESS,
} from "@/constants";
import rootStore, { gameStore, lootboxesStore, walletStore } from "@/store";
import { externalLink } from "@/utils/handleExpandPage";
import { buildMintLootBoxTx, buildOpenLootBoxTx } from "@/utils/mintNft";
import { buyMoneyBagState } from "@/views/BuyMoneyBag/state";
import { useSuiClient } from "@mysten/dapp-kit";
import { KioskClient, Network } from "@mysten/kiosk";
import ky from "ky";
import { action, observable } from "mobx";
import { balance, isTokenExpired } from "@/utils";
import { backend } from "@/services";

const moneyBagState = observable({
  isMoneyBagOpen: false,
});

const setMoneyBag = action((state: boolean) => {
  moneyBagState.isMoneyBagOpen = state;
});

const base = isProdUrl ? apiProduction : apiStage;

export const useMoneyBag = () => {
  const client = useSuiClient();

  const kioskClient = new KioskClient({
    client,
    network: Network.MAINNET,
  });

  const buyMoneyBag = async () => {
    if (
      gameStore.balance < 5000000 * buyMoneyBagState.amount ||
      Number(balance(walletStore.balance)) < 0.02
    ) {
      buyMoneyBagState.isNotEnoughCoins = true;
      return;
    }

    if (isTokenExpired(localStorage.getItem("token") ?? "")) {
      localStorage.removeItem("token");
      rootStore.setLoading(true);
      await backend.reauth();
    }

    if (walletStore.walletType === "OKX") {
      ky.get(base + `/api/nft/lootbox/sign?count=${buyMoneyBagState.amount}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((result) => result.json() as Promise<SignType>)
        .then((data) => {
          if (data.ok) {
            gameStore.addBalance(-5000000);
            const uid = BigInt(data.random_id);
            const signature = new Uint8Array(
              Buffer.from(data.signature, "base64")
            );

            buildMintLootBoxTx({
              packageAddress: PACKAGE_ADDRESS,
              uid,
              dataStorage: DataStorage,
              signature,
              count: buyMoneyBagState.amount,
              validUntil: data.valid_until,
              kioskClient,
              kioskOwnerCap: walletStore.address ?? "",
            }).then((mintLootboxTx) => {
              const input = {
                transactionBlock: mintLootboxTx,
                chain: "sui:mainnet",
                options: {
                  showEffects: true,
                },
              };
              rootStore.setLoading(false);

              walletStore.OKXSuiProvider?.signAndExecuteTransaction(input)
                .then((result) => {
                  const handledResult = result as {
                    digest: string;
                  };

                  ky.post(base + `/api/nft/lootbox/mint_tx`, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    json: {
                      tx_digest: handledResult.digest,
                    },
                  });
                  lootboxesStore.syncLootboxes(walletStore.address ?? "");
                  setMoneyBag(true);
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          }
        })
        .catch(() => {
          rootStore.setLoading(false);
        });
    } else {
      closeMoneyBag();
      externalLink("/MintMoneyBag", {
        count: buyMoneyBagState.amount.toString(),
        token: localStorage.getItem(`token`) ?? "",
        address: walletStore.address ?? "",
      });
    }
  };

  const openMoneyBag = () => {
    moneyBagState.isMoneyBagOpen = false;
    if (walletStore.walletType === "OKX" && walletStore.address) {
      buildOpenLootBoxTx({
        transferLootboxId: lootboxesStore.getLootBoxId(),
        dataStorage: DataStorage,
        packageAddress: PACKAGE_ADDRESS,
        emptyTp: EMPTY_TP,
        userAddress: walletStore.address,
        kioskClient: kioskClient,
      }).then((openLootboxTx) => {
        const input = {
          transactionBlock: openLootboxTx,
          chain: "sui:mainnet",
          options: {
            showEffects: true,
          },
        };

        walletStore.OKXSuiProvider?.signAndExecuteTransaction(input)
          .then((result) => {
            const handledResult = result as unknown as {
              digest: string;
            };

            ky.post(base + `/api/nft/lootbox/open_tx`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              json: {
                tx_digest: handledResult.digest,
              },
            }).then((result) => {
              result.json().then((result) => {
                const handledResult = result as {
                  ok: boolean;
                  reward: number;
                };
                setMoneyBag(false);
                buyMoneyBagState.isMoneyBagOpened = true;
                lootboxesStore.syncLootboxes(
                  walletStore.address ?? "",
                  handledResult.reward
                );
              });
            });
          })
          .catch(() => {});
      });
    } else if (walletStore.walletType === "STASHED") {
      externalLink("/OpenMoneyBag", {
        moneyBagId: lootboxesStore.getLootBoxId(),
        token: localStorage.getItem(`token`) ?? "",
        address: walletStore.address ?? "",
      });
    }
  };

  const openMoneyBagModal = () => {
    if (lootboxesStore.bags.length > 0) {
      setMoneyBag(true);
    }
  };

  const closeMoneyBag = () => {
    setMoneyBag(false);
  };

  return {
    openMoneyBag,
    closeMoneyBag,
    openMoneyBagModal,
    buyMoneyBag,
    moneyBagState,
  };
};
