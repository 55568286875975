import { FC } from "react";
import { observer } from "mobx-react-lite";

import promoLogo from "@/assets/icons/promoLogo.png";
import { Button } from "@/components/Button";
import webappInfo from "@/assets/capybara-images/webapp-event-info.png";
import styles from "./styles.module.sass";
import { DialogComponents } from "@/components/Dialog";

type PromoWebAppProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAction: VoidFunction;
};

export const PromoWebApp: FC<PromoWebAppProps> = observer(
  ({ isOpen, onClose, onAction }) => {
    return (
      <DialogComponents.Container
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
        isOpen={Boolean(isOpen)}
        onAction={onAction}
        onClose={onClose}
      >
        {({ handleClose }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              className={styles.modalCloser}
              onClick={handleClose}
            ></DialogComponents.Closer>
            <div className={styles.capybag}>
              <img
                alt="capybag"
                src={promoLogo}
                style={{ maxWidth: "150px" }}
              />
            </div>
            <DialogComponents.TextContainer className={styles.textContainer}>
              <DialogComponents.Title className={styles.modalTitle}>
                {`BIG EVENT is Coming in Q1!`}
              </DialogComponents.Title>
              <DialogComponents.Text className={styles.modalText}>
                <span
                  style={{
                    lineHeight: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <p>
                    <b>On-chain functionality</b> is now available exclusively on the 
                    <b> Web App</b>
                    🌐✨
                  </p>
                  <p>
                    <b>Use the Web App to access your wallet and NFTs</b>, and lock in your latest balance for the <b>BIG EVENT!</b> 💰🎉
                  </p>
                  <img
                    style={{
                      width: "100%",
                      paddingTop: "6px",
                    }}
                    src={webappInfo}
                    alt=""
                  />
                  <p>
                    The more Bags you open, the bigger your reward for the BIG EVENT!
                  </p>
                </span>
              </DialogComponents.Text>
            </DialogComponents.TextContainer>
            <div style={{ width: "100%" }}>
              <Button
                onClick={onAction}
                label="Go to Web App"
                styleType="primary"
                block={true}
              ></Button>
            </div>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
    );
  }
);
